

import http from '@/utils/http'

import qs from 'qs'
const config = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
}
// 获取学生详情
export const getStudentDteailOver = idCard => {
  return http.get(`hxb-backend/qyWx/idCard/${idCard}`)
}
// 学生信息
export const getStudentInfo = idCard => {
  return http.get(`hxb-backend/examination/getStudentInfo/${idCard}`)
}
// 学生考籍
export const getStudentExaminationInfo = data => {
  return http.get(`hxb-backend/examination/getStudentExaminationInfo`, {params: data})
}
// 学生订单
export const getAppOrderList = data => {
  return http.post(`hxb-backend/examination/getAppOrderList`,qs.stringify(data),config)
}
// 学生学习详情
export const getStudentStudyInfo = uid => {
  return http.get(`hxb-backend/qyWx/user/${uid}/studentStudyInfo`)
}
// 获取考籍详情异议处理列表
export const getObjectDeal = (data, page, pageSize) => {
  return http.post(`hxb-backend/examination/getObjectDeal/${page}/${pageSize}`,data)
}