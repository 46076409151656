<template>
  <div class="overflow_scroll">
    <van-skeleton title avatar :row="6" :loading="loading">
      <ul v-if="dataList && dataList.length" class="examination_list">
        <li
          class="examination_item"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <p class="examination_item_tit">{{formatExamination(item)}}</p>
           <p class="row">
            <span class="tit">报考类型</span>
             <span class="detail ml20">{{
              item.waitChangeType == 2
                ? item.applyTypeName
                : `${item.applyTypeName}·${item.changeBatchNo}次待转应用型`
            }}</span>
          </p>
          <p class="row">
            <span class="tit">批次</span>
            <span>{{ item.batchNo }}次</span>
          </p>
          <p class="row">
            <span class="tit">状态</span>
            <span>{{ item.statusName }}</span>
          </p>
          <p class="row">
            <span class="tit">APP开通</span>
            <span>{{
              item.app === 1
                ? `已开通${item.appInfo ? item.appInfo : ""}`
                : "未开通"
            }}</span>
          </p>
          <p class="row">
            <span class="tit">当前步骤</span>
            <span>{{ `${item.currentStepId && item.currentStepName ? `${item.currentStepId}-${item.currentStepName}` : '--'}` }}</span>
          </p>
          <p class="row">
            <span class="tit">开通人</span>
            <span>{{ item.creator }}</span>
          </p>
          <p class="row">
            <span class="tit">负责人</span>
            <span>{{ item.teacherName }}</span>
          </p>
        </li>
      </ul>
      <van-empty v-else description="这里空空的~" />
    </van-skeleton>
  </div>
</template>
<script>
import { reactive, toRefs,watch } from "vue";
import { Empty, Skeleton } from "vant";
import { getStudentExaminationInfo } from "@/api/studentDetails";
import {goodsBigTypeList, degreeList} from '@/utils/const'
import {matchLabel} from '@/utils/commUtil'
export default {
  components: {
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton,
  },
  props: {
    idCard: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      loading: false,
      dataList: [],
    });
    const formatExamination=(element)=>{
      const signType=matchLabel(goodsBigTypeList,'type','name',element.signType)
      const level = matchLabel(degreeList, 'type', 'name', element.level)
      return [signType,element.provinceName,element.schoolName,element.examinationName,level].filter(Boolean).join('·')
    }
    const getList = () => {
      const params = {
        idCard: props.idCard
      }
      state.loading = true
      getStudentExaminationInfo(params).then((res) => {
        state.loading = false;
        if (res && res.body) {
          state.dataList = res.body;
        } else {
          state.dataList = [];
        }
      });
    };
    watch(()=>props.idCard,()=>{
      getList()
    })
    getList();
    return {
      ...toRefs(state),
      formatExamination
    };
  },
};
</script>
<style lang="less" scoped>
.examination_list {
  .examination_item {
    padding: 32px;
    background-color: #fafafa;
    border-radius: 20px;
    margin-bottom: 20px;
    .examination_item_tit {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .row {
      margin-bottom: 20px;
      .tit {
        color: #999;
        display: inline-block;
        width: 112px;
        margin-right: 32px;
        white-space: nowrap;
      }
    }
  }
}
</style>