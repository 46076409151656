<template>
  <div class="overflow_scroll">
    <orderType :orderType="orderType" @changeType="changeType" />
    <van-skeleton title :row="6" :loading="loading">
      <ul v-if="dataList&&dataList.length" class="order_list">
        <li class="order_item" v-for="(item,index) in dataList" :key="index">
          <p class="order_item_tit">
            <span class="order_item_title">{{formatGoods(item.signType,item.goodsList,item)}}</span>
            <span class="add_tips">{{formatOrderType(item.signType)}}</span>
          </p>
          <p class="row">
            <span class="tit">消费类型</span>
            <span>{{formatConsumetype(item.consumeType) }}</span>
          </p>
          <p class="row">
            <span class="tit">价格体系</span>
            <span>{{item.priceSystem||'--'}}</span>
          </p>
          <p class="row">
            <span class="tit">金额</span>
            <span>¥{{formatOrderMoney(item.payMoney)}}</span>
          </p>
          <p class="row">
            <span class="tit">支付方式</span>
            <span>{{formatBuyType(item.platform)}}</span>
          </p>
          <p class="row">
            <span class="tit">状态</span>
            <span>{{formatPayStatus(item.status)}}</span>
          </p>
          <p class="row">
            <span class="tit">开通人</span>
            <span>{{item.openPersonName}}</span>
          </p>
          <p class="row">
            <span class="tit">创建时间</span>
            <span>{{formatTime(item.createdAt) }}</span>
          </p>
          <p class="row">
            <span class="tit">支付时间</span>
            <span>{{formatTime(item.updatedAt) || '--'}}</span>
          </p>
        </li>
      </ul>
      <van-empty v-else description="这里空空的~" />
    </van-skeleton>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from "vue";
import { Empty, Skeleton } from "vant";
import { matchLabel, formatMoney, formatTimeMs } from "@/utils/commUtil";
import { getAppOrderList } from "@/api/studentDetails";
import {
  consumeTypeList,
  consumeTypeList2,
  buyTypeList,
  orderStatusList,
} from "@/utils/const";
import orderType from "./orderType";
export default {
  components: {
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton,
    orderType,
  },
  props: {
    idCard: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      loading: false,
      orderType: "1",
      dataList: [],
    });
    const formatGoods = (signType, goods, item) => {
      let str = "";
      if (signType === 1) { // 单门课程
        str = goods[0].courseName;
        if (goods.length > 1) {
          str += "等";
          str += goods.length;
          str += "门课程";
        }
      } else if(signType === 2) { // 整专业
        str = goods[0].courseName;
      } else if (signType === 3) { // 单模块
        str = goods[0].courseName;
        if (goods.length > 1) {
          str += "等";
          str += goods.length;
          str += "个模块";
        }
      } else { //单点
          str = goods[0].courseName + `【${item.goodsNo}】的视频解析`;
      }
      return str;
    };
    const formatConsumetype = (consumetype) => {
      return (
        matchLabel(
          state.orderType === "1" || state.orderType === "2"
            ? consumeTypeList
            : consumeTypeList2,
          "value",
          "label",
          consumetype
        ) || "--"
      );
    };
    const formatOrderType = (type) => {
      const arr = ["单科", "整专业", "单模块", "单点"];
      return arr[type - 1];
    };
    const formatOrderMoney = (money) => {
      return money || money === 0 ? `${formatMoney(money)}` : "--";
    };
    const formatBuyType = (buyType) => {
      return matchLabel(buyTypeList, "value", "label", buyType) || "--";
    };
    const formatPayStatus = (status) => {
      return matchLabel(orderStatusList, "value", "label", status) || "--";
    };
    const formatTime = (time) => {
      return formatTimeMs(time) || "--";
    };
    const changeType = (orderType) => {
      state.orderType = orderType;
      getList();
    };
    const getList = () => {
      const params = {
        idCard: props.idCard,
        orderType: state.orderType,
      };
      state.loading = true;
      getAppOrderList(params).then((res) => {
        state.loading = false;
        if (res && res.body) {
          state.dataList = res.body;
        } else {
          state.dataList = [];
        }
      });
    };

    watch(
      () => props.idCard,
      () => {
        getList();
      }
    );
    getList();
    return {
      ...toRefs(state),
      formatGoods,
      formatConsumetype,
      formatOrderMoney,
      formatBuyType,
      formatPayStatus,
      formatOrderType,
      formatTime,
      changeType,
    };
  },
};
</script>
<style lang="less" scoped>
.order_list {
  .order_item {
    padding: 32px;
    background-color: #fafafa;
    border-radius: 20px;
    margin-bottom: 20px;
    .order_item_tit {
      font-size: 32px;
      margin-bottom: 24px;
      display: flex;
      align-items: top;
      justify-content: space-between;
      .order_item_title {
        font-weight: bold;
      }
      .add_tips {
        display: inline-block;
        min-width: 92px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 20px;
        margin-left: 20px;
        border-radius: 18px;
        background: rgba(22, 120, 255, 0.1);
        color: #1678ff;
      }
    }
    .row {
      margin-bottom: 20px;
      .tit {
        color: #999;
        display: inline-block;
        width: 112px;
        margin-right: 32px;
        white-space: nowrap;
      }
    }
  }
}
</style>