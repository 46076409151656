<!--
 * @Author: your name
 * @Date: 2021-06-01 17:09:35
 * @LastEditTime: 2021-06-03 20:20:28
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \hxb-companyd:\workplace\qy_wx_h5\src\views\studentDetails\components\orderType.vue
-->


<template>
<div id="group_wrapper" class="group_wrapper" v-slide:sync>
  <div class="group_list">
    <p v-for="item in groupList" :key="item.name" class="group_item" :class="{active:current===item.name}" @click.stop="handleActive(item.name)">
      {{item.label}}
    </p>
  </div>
</div>
</template>
<script>
import { reactive,toRefs } from 'vue'
import {slide} from '@/directive/slide'
export default {
  props:{
    orderType:{
      type:String,
      default:'1'
    }
  },
  emits:['changeType'],
  directives:{slide},
  setup(props,context) {
     const state=reactive({
        current:null,
        groupList:[
          {
            label: '直招开通',
            name: '1'
          },
          {
            label: '教务开通',
            name: '2'
          },
          {
            label: '自行购买',
            name: '3'
          },
          {
            label: '客服订单',
            name: '4'
          },
          {
            label: '推广订单',
            name: '5'
          },
          {
            label: '机构开通',
            name: '6'
          }
        ]
     })
    state.current=props.orderType
    const handleActive=(type)=>{
      state.current=type
      context.emit('changeType',type)
    }

    return {
      ...toRefs(state),
      handleActive
    }
  }
}
</script>
<style lang="less" scoped>
.group_wrapper{
  position: relative;
  width: 100%;
  overflow: hidden;
}
.group_list{
  &::-webkit-scrollbar {
    display: none;
  }
  .group_item{
    display: inline-block;
    cursor: pointer;
    color: #999;
    min-width: 120px;
    text-align: center;
    padding: 8px 24px;
    border: 1px solid #dddddd;
    border-radius: 31px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 24px;
    box-sizing: border-box;
    &:not(:last-of-type){
      margin-right: 12px;
    }
    &.active{
      color:#1678FF;
      border-color: #1678FF;
      background: rgba(22,120,255,0.10);
    }
  }
}
</style>