<template>
  <div class="overflow_scroll">
    <van-skeleton title :row="6" :loading="loading">
    <van-list
      v-model="loadingList"
      :finished="finished"
      finished-text="没有更多了"
      v-model:error.sync="error"
      error-text="请求失败，点击重新加载"
      @load="onLoad"
    >
    <ul v-if="dataList&&dataList.length" class="study_list">
        <li class="study_item" v-for="(item,index) in dataList" :key="index">
          <p class="study_item_tit">
            <span>{{formatType(item.type)}}</span>
            <span class="tag" :class="formatStatus(item.status,'color')">{{formatStatus(item.status,'label')}}</span>
          </p>
          <div class="row fs28 describe">
            {{item.describe}}
          </div>
          <p class="row">
            <span class="tit">提交时间</span>
            <span>{{formatTime(item.createAt)}}</span>
          </p>
          <p class="row">
            <span class="tit">响应时间</span>
            <span>{{formatTimediff(item.responseAt)}}</span>
          </p>
          <p class="row">
            <span class="tit">完结时间</span>
            <span>{{formatTime(item.completeAt)}}</span>
          </p>
          <p v-if="item.status===4" class="row">
            <span class="tit">星级评价</span>
            <span class="student_star">
              <i v-if="item.evaluation>=1" class="iconfont hxb-icon_xinngxing2"></i>
              <i v-if="item.evaluation>=2" class="iconfont hxb-icon_xinngxing2"></i>
              <i v-if="item.evaluation>=3" class="iconfont hxb-icon_xinngxing2"></i>
              <i v-if="item.evaluation>=4" class="iconfont hxb-icon_xinngxing2"></i>
              <i v-if="item.evaluation>=5" class="iconfont hxb-icon_xinngxing2"></i>
            </span>
          </p>
        </li>
     </ul>
      <van-empty v-else description="这里空空的~" />
    </van-list>
    </van-skeleton>
  </div>
</template>
<script>
import { Progress ,List,Empty,Skeleton} from 'vant'
import { reactive,toRefs } from 'vue'
import {matchLabel,formatTimeDiff,formatTimeMs} from '@/utils/commUtil'
import {getObjectDeal} from '@/api/studentDetails'
import {objectionTypeList,objectionStatusList} from '@/utils/const'
export default {
  components: {
    [Progress.name]: Progress,
    [Empty.name]: Empty,
    [List.name]: List,
    [Skeleton.name]: Skeleton,
  },
  props: {
    idCard: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      loading:false,
      loadingList:false,
      finished:false,
      error:false,
      page:1,
      pageSize:10,
      total:0,
      dataList:[],
      noData:false
    })
    const formatType=(type)=>{
      return matchLabel(objectionTypeList,'value','label',type)
    }
    const formatStatus=(status,key)=>{
      return matchLabel(objectionStatusList,'value',key,status)
    }
    const formatTimediff=(time)=>{
      return formatTimeDiff(time)||'--'
    }
    const formatTime=(time)=>{
      return formatTimeMs(time)||'--'
    }
    const getList=() =>{
      const params={
        entrance:3,
        idCard:props.idCard
      }
      if(!state.dataList||!state.dataList.length){
        state.loading=true
      }else{
        state.loadingList=true
      }
      return getObjectDeal(params, state.page, state.pageSize).then((res) => {
        state.loading = false;
        state.loadingList = false;
        if (res && res.body) {
          const { data, total, page } = res.body;
          if (data.length) {
            state.dataList = state.dataList.concat(data);
            state.total = total;
            state.page = page+1;
          }
        }
      });
    };
    const onLoad = () => {
      if(state.finished||state.noData)return
      getList()
        .then(() => {
          if (state.dataList.length && state.dataList.length === state.total) {
            state.finished = true;
          }else if(!state.dataList || !state.dataList.length){
            state.noData = true;
          }
        })
        .catch(() => {
          state.error = true;
        });
    };
    return {
      ...toRefs(state),
      formatType,
      formatStatus,
      formatTimediff,
      formatTime,
      onLoad,
      getList,
    };
  },
};
</script>
<style lang="less" scoped>
.study_list {
  .study_item {
    padding: 32px;
    background-color: #fafafa;
    border-radius: 20px;
    margin-bottom: 20px;
    .study_item_tit {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .tag {
        font-size: 20px;
        font-weight: normal;
        line-height: normal;
        color: #1678ff;
        border-radius: 18px;
        width: 92px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: rgba(22, 120, 255, 0.1);
        border-radius: 18px;
        &.success {
          color: #00c0c0;
          background: rgba(0, 192, 192, 0.1);
        }
        &.warning {
          color: #ff9736;
          background: rgba(255, 151, 54, 0.1);
        }
        &.cancel {
          color: #999;
          background: rgba(153, 153, 153, 0.1);
        }
      }
    }
    .describe {
      word-break: break-all;
    }
    .row {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .tit {
        color: #999;
        display: inline-block;
        width: 112px;
        margin-right: 32px;
        white-space: nowrap;
        flex-shrink: 0;
      }
    }
  }
}
</style>