<template>
  <div v-if="idCard" class="student_details">
    <!-- <div class="bg_header"></div> -->
    <div ref="container" class="details_content" @scroll="handleScroll">
      <!-- 头部 -->
      <div class="my_card mb20">
        <van-skeleton title avatar :row="4" :loading="loading">
          <div class="student_info_top">
            <img class="avatar" :src="basicInfo.avatar || '/imgs/avatar.png'" alt="">
            <p class="student_name mb6 ml24">{{basicInfo.name}}</p>
          </div>
          <van-row>
            <van-col span="12">
              <span class="info_title">手机号</span>
              <span>{{basicInfo.appUserName ? basicInfo.appUserName: basicInfo.mobile}}</span>
            </van-col>
            <!-- <van-col span="12">
              <span class="info_title">微信号</span>
              <span>yangtuo拓</span>            
            </van-col> -->
          </van-row>
          <!-- <van-row>
            <van-col span="12">
              <span class="info_title">QQ</span>
              <span>yangtuo拓</span>            
            </van-col>
          </van-row> -->

          <div class="divider"></div>

          <van-row class="mt20">
            <van-col span="24">
              <span class="info_title">关联销售人</span>
              <span>{{basicInfo.saleName || '--'}}</span>
            </van-col>
          </van-row>
          <van-row class="mb0">
            <van-col span="24">
              <span class="info_title">关联班主任</span>
              <span>{{basicInfo.teacherName || '--'}}</span>
            </van-col>
          </van-row>
        </van-skeleton>
      </div>

      <!-- 底部tab详情  :offset-top="32"-->
      <div class="my_card sticky_container">
        <van-sticky :container="container"> 
          <div class="van_tabs">
            <van-tabs v-model:active="currentTab" :before-change="beforeChange">
              <van-tab v-for="(item,index) in tabs" :key="index" :title="item.title" :name="item.name"></van-tab>
            </van-tabs>
          </div>
        </van-sticky>
        <component ref="tabComponent" :is="componentName" :idCard="idCard" :uid="uid"></component>
      </div>
    </div>
  </div>
  <van-empty v-else description="未查询到该学生信息" />
</template>
<script>
import {getStudentIdCard} from '@/api'
import {getStudentDteailOver} from '@/api/studentDetails'
import { computed,reactive,toRefs } from 'vue'
import { Col,Row,Tabs,Tab,Sticky,Empty,Skeleton } from 'vant'
import {matchLabel} from '@/utils/commUtil'
import examination from './components/examination'
import order from './components/order'
import study from './components/study'
import objection from './components/objection'
export default {
  name:'StudentDetails',
  components:{
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Sticky.name]: Sticky,
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton,
    examination,
    order,
    study,
    objection
  },
  setup() {
    const state=reactive({
      tabs:[
        {
          title:'考籍',
          name:1,
          component:'examination'
        },
        {
          title:'订单',
          name:2,
          component:'order'
        },
        {
          title:'学习',
          name:3,
          component:'study'
        },
        {
          title:'异议',
          name:4,
          component:'objection'
        }
      ],
      currentTab:1,
      uid:null,
      loading:false,
      tabComponent:null,
      isFixed:false,
      idCard:null,
      basicInfo:{},
      container:null
    })
    const componentName=computed(()=>{
      return matchLabel(state.tabs,'name','component',state.currentTab)
    })

    const getIdcard=()=>{
      const customerQyWxUserId =localStorage.getItem('customerQyWxUserId')
      const params={
        customerQyWxUserId
      }
      return getStudentIdCard(params).then(res=>{
        if(res&&res.body){
          state.idCard=res.body
        }
      })
    }
    const getBasicInfo=()=>{
      state.loading=true
      getStudentDteailOver(state.idCard).then(res => {
        state.loading=false
        if (res && res.body) {
          state.basicInfo = res.body
        }else{
          state.basicInfo={}
        }
        state.uid=state.basicInfo.uid
      })
    }
    getIdcard().then(()=>{
      if(state.idCard){
        getBasicInfo()
      }
    })
    const beforeChange=()=>{
      // console.log(state.tabComponent)
      // state.tabComponent.$el.scrollIntoView({block:'start'})
      return true
    }
    return {
      ...toRefs(state),
      componentName,
      beforeChange
    }
  }
}
</script>
<style lang="less" scoped>
@import './index';
</style>