<template>
  <div class="overflow_scroll">
    <van-skeleton title :row="6" :loading="loading">
      <ul v-if="dataList&&dataList.length" class="study_list">
        <li class="study_item" v-for="(item,index) in dataList" :key="index">
          <p class="study_item_tit">
            <span>{{item.shortForm}}·{{item.courseNo}}</span>
            <span
              class="tag"
              :class="formatLevel(item.courseLevel,'color')"
            >{{formatLevel(item.courseLevel,'label')}}</span>
          </p>
          <p class="row">
            <span class="tit">课程状态</span>
            <span>
              <span :class="formatStatus(item.courseStatus,'color')">{{item.courseStatus}}</span>
              <span
                v-if="item.courseStatus==='正常'||item.courseStatus==='已过期'"
              >·{{formatTime(item.overdueAt?item.overdueAt*1000:null)}}</span>
            </span>
          </p>
          <div class="study_moudle_wrapper" v-if="item.singleList.length">
            <p class="study_moudle_title">单模块:</p>
            <div class="row" v-for="(child, index) in item.singleList" :key="index">
              <span class="tit">{{ formatReportType(child.signLevel)}}</span>
              <div>
                <span :class="formatStatus(child.status,'color')">{{child.status}}</span>
                <span
                  v-if="child.status==='正常'||child.status==='已过期'"
                >·{{formatTime(child.overdueAt?child.overdueAt:null)}}过期</span>
              </div>
            </div>
          </div>
          <p class="row">
            <span class="tit">听课进度</span>
            <van-progress
              :percentage="item.videoProgress"
              color="linear-gradient(90deg,#80b5ff, #1678ff 100%)"
              :show-pivot="false"
            />
            <span class="ml32">{{formatNum(item.videoProgress)}}%</span>
          </p>
          <p class="row">
            <span class="tit">练题进度</span>
            <van-progress
              :percentage="item.exercisesProgress"
              color="linear-gradient(90deg,#80b5ff, #1678ff 100%)"
              :show-pivot="false"
            />
            <span class="ml32">{{formatNum(item.exercisesProgress)}}%</span>
          </p>
          <p class="row">
            <span class="tit">直播观看</span>
            <span>{{item.live}}</span>
          </p>
        </li>
      </ul>
      <van-empty v-else :description="uid?'这里空空的~':'该学生暂未绑定APP哦~'" />
    </van-skeleton>
  </div>
</template>
<script>
import { Progress, Empty, Skeleton } from "vant";
import { reactive, toRefs, watch } from "vue";
import { matchLabel, formatTimeMs, formatNumber } from "@/utils/commUtil";
import { getStudentStudyInfo } from "@/api/studentDetails";
// import notify from '@/vant/notify'
import { levelList, studyStatus,reportTypeList } from "@/utils/const";
export default {
  components: {
    [Progress.name]: Progress,
    [Empty.name]: Empty,
    [Skeleton.name]: Skeleton,
  },
  props: {
    uid: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const state = reactive({
      loading: false,
      dataList: [],
    });
    const formatStatus = (status, key) => {
      return matchLabel(studyStatus, "label", key, status);
    };
    const formatLevel = (status, key) => {
      return matchLabel(levelList, "value", key, status);
    };
    const formatReportType = (status) => {
      return matchLabel(reportTypeList, "id", 'name', status);
    }
    const formatTime = (time) => {
      return formatTimeMs(time) || "--";
    };
    const formatNum = (time) => {
      return formatNumber(time) || "0";
    };
    const formatLive = (liveData) => {
      const arr = liveData.split("/");
      const live = +arr[0];

      if (live) return "已观看";
      return "未观看";
    };

    const getList = () => {
      if (!props.uid) {
        // notify.warning('该学生暂未绑定APP')
        state.dataList = [];
        return;
      }
      state.loading = true;
      getStudentStudyInfo(props.uid).then((res) => {
        state.loading = false;
        if (res && res.body) {
          state.dataList = res.body.map((item) => {
            return {
              ...item,
              videoProgress: item.videoProgress ? item.videoProgress * 100 : 0,
              exercisesProgress: item.exercisesProgress
                ? item.exercisesProgress * 100
                : 0,
            };
          });
        }
      });
    };

    watch(
      () => props.uid,
      () => {
        getList();
      }
    );
    getList();
    return {
      ...toRefs(state),
      formatStatus,
      formatLevel,
      formatLive,
      formatTime,
      formatNum,
      formatReportType
    };
  },
};
</script>
<style lang="less" scoped>
.study_list {
  .study_item {
    padding: 32px;
    background-color: #fafafa;
    border-radius: 20px;
    margin-bottom: 20px;
    .study_item_tit {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .tag {
        font-size: 20px;
        font-weight: normal;
        color: #1678ff;
        border-radius: 18px;
        min-width: 92px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        background: rgba(22, 120, 255, 0.1);
        &.success {
          color: #00c0c0;
          background: rgba(0, 192, 192, 0.1);
        }
        &.warning {
          color: #ff9736;
          background: rgba(255, 151, 54, 0.1);
        }
      }
    }
    .study_moudle_wrapper {
      border-top: 1px dashed #ddd;
      border-bottom: 1px solid dashed #ddd;
      margin: 48px 0px;
      .study_moudle_title {
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: bold;
        color: #666;
      }
    }
    .row {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .tit {
        color: #999;
        display: inline-block;
        min-width: 112px;
        margin-right: 32px;
        white-space: nowrap;
        flex-shrink: 0;
      }
    }
    :deep(.van-progress) {
      height: 8px;
      width: 310px;
    }
  }
}
</style>